import React, { useState, useEffect, useContext, useRef } from 'react'
import PropTypes from 'prop-types'
import { Index } from 'elasticlunr'
import styled from 'styled-components'
import breakpoint from '@utils/breakpoint'
import tw from 'twin.macro'
import { FormattedMessage } from 'react-intl'

import ToggleComponentContext from '@providers/toggleComponentProvider'
import Icon from '@objects/icon'

const SearchIcon = styled(Icon)`
  margin-left: 6px;
  margin-bottom: 2px;
`

function Search({ className, searchIndex }) {
  const CompRef = useRef()
  const { toggleOverlay } = useContext(ToggleComponentContext)

  const [index, setIndex] = useState(null)
  const [trigger, setTrigger] = useState(null)

  useEffect(() => {
    if (!index) setIndex(Index.load(searchIndex))
    setTrigger(CompRef.current)
  }, [])

  function showOverlay(show) {
    toggleOverlay(show, 'search', {
      trackingID: 'suche',
      className: 'o-overlay__search',
      index: index,
      trigger: trigger,
    })
  }

  return (
    <button
      ref={CompRef}
      className={className}
      onClick={() => showOverlay(true)}
    >
      <span>
        <FormattedMessage id="navigation.search" />
      </span>
      <SearchIcon name="Search" className="text-3xl" />
    </button>
  )
}

Search.propTypes = {
  className: PropTypes.string,
  searchIndex: PropTypes.object,
}

export default styled(Search).attrs({
  className:
    'flex items-center text-lg font-medium leading-3 hover:text-red cursor-pointer',
})`
  &:focus {
    outline: 1px dotted #000;
  }
  span {
    ${tw`hidden`}
    ${breakpoint('lg')`
      ${tw`block`}`}
  }
`
