import React, { useEffect, useState, useRef } from 'react'
import { Link, navigate } from 'gatsby'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import styled from 'styled-components'
import breakpoint from '@utils/breakpoint'
import { FormattedMessage } from 'react-intl'
import { useTracking, useToggleComp, useTheme } from '@hooks'

import Icon from '@objects/icon'
import Button from '@objects/button'
import SearchResultIcon from '@objects/searchResultIcon'

const StyledInput = styled.input.attrs({
  className:
    'w-full pt-0.5 px-7 text-2lg bg-transparent border-0 md:border-2 md:border-blue md:rounded-lg',
})`
  line-height: 1;
  caret-color: ${({ theme }) => theme.colors.red.default};
  height: 46px;
  &:focus {
    outline: 1px dotted #000;
  }
  &::placeholder {
    color: ${({ theme }) => theme.colors.black[650]};
  }
`

const StyledLink = styled(Button).attrs({
  className: 'ml-7',
})``

const StyledArrow = styled(Icon).attrs({
  name: 'TextArrow',
  className: 'pl-0.5 text-3xl',
})`
  margin-top: -3px;
`

const StyledAutoSuggests = styled.div.attrs({
  className:
    'absolute flex flex-col justify-start items-start bottom-0 left-0 w-full p-3 transition-opacity duration-200',
})`
  transform: translateY(100%);
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;

  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: -1;
  background-color: #fff;

  &.has-results {
    opacity: 1;
    visibility: visible;
    pointer-events: inherit;
    z-index: 10;
  }

  a {
    position: relative;
    .result-type-icon {
      position: absolute;
      left: -${({ theme }) => theme.spacing['1']};
      top: ${({ theme }) => theme.spacing['2']};
    }
  }
`

function SearchInput({
  className,
  searchfunc,
  resultsAll,
  noautosuggest,
  searchquery,
  onKeyEnter,
  onSuggestClick,
  isShown,
}) {
  const { pushMessage } = useTracking()
  const { toggleOverlay } = useToggleComp()
  const inputRef = useRef(null)
  const suggestRef = useRef(null)
  const [forceSuggestHide, setForceSuggestHide] = useState(false)
  const [TrackingTimeOut, setTrackingTimeOut] = useState(() => {})
  const { screens } = useTheme()
  const [isMobile, setIsMobile] = useState(false)
  const [suggestPosition, setSuggestPosition] = useState(-1)

  useEffect(() => {
    if (searchquery && inputRef.current.value !== searchquery)
      inputRef.current.value = searchquery
  }, [searchquery])

  useEffect(() => {
    if (isShown) {
      setTimeout(() => {
        inputRef.current.focus()
      }, 200)
    }
  }, [isShown])

  useEffect(() => {
    setIsMobile(window.innerWidth < parseInt(screens.md, 10))
  }, [screens])

  function renderAutoSuggest() {
    return [
      ...resultsAll.slice(0, 5).map((result, i) => {
        return (
          <Link
            key={`${result.slug}${i}`}
            className="py-1 px-3 text-lg font-medium"
            to={result.path}
            onClick={suggestClick}
          >
            <SearchResultIcon type={result.type} />
            {result.title}
            <StyledArrow className="text-black" />
          </Link>
        )
      }),
      <Link
        key="toallresults"
        className="py-1 px-3 text-lg font-medium text-red"
        to={`/suchergebnisse/#searchquery=${inputRef.current?.value}`}
        onClick={() => {
          suggestClick()
        }}
      >
        <FormattedMessage
          id="search.suggest.all"
          values={{
            count: resultsAll.length > 5 ? resultsAll.length : '',
          }}
        />
        <StyledArrow className="text-red" />
      </Link>,
    ]
  }

  function suggestClick() {
    if (onSuggestClick) onSuggestClick()
  }

  function inputChangeEvent(ev) {
    clearTimeout(TrackingTimeOut)
    const value = ev.target.value

    setForceSuggestHide(value.length < 4)
    searchfunc(value)

    // put trackingevent in a timeout to prevent spamming unnessecary query-strings
    if (value.length > 3) {
      setTrackingTimeOut(
        setTimeout(() => {
          pushMessage('custom.search-pushed', {
            searchquery: value,
          })
        }, 1000)
      )
    }
  }

  function onInputKeydown(ev) {
    if (ev.key === 'Enter') {
      navigate(`/suchergebnisse/#searchquery=${inputRef.current?.value}`)
      if (onKeyEnter) onKeyEnter()
    }
  }

  function onKeydown(ev) {
    if (suggestRef?.current) {
      const links = suggestRef.current.querySelectorAll('a')
      if (
        ev.key === 'ArrowDown' ||
        (ev.key === 'Tab' && !ev.shiftKey && suggestPosition > -1)
      ) {
        const newpos = suggestPosition + 1
        if (newpos < links.length) {
          links[newpos].focus()
          setSuggestPosition(newpos)
          ev.preventDefault()
        }
      } else if (
        suggestPosition > -1 &&
        (ev.key === 'ArrowUp' || (ev.key === 'Tab' && ev.shiftKey))
      ) {
        const newpos = suggestPosition - 1
        if (newpos < 0) {
          // eslint-disable-next-line no-unused-expressions
          inputRef.current?.focus()
        } else {
          links[newpos].focus()
        }
        setSuggestPosition(newpos)
        ev.preventDefault()
      }
    }
  }

  return (
    <div className={clsx(className)}>
      <StyledInput
        className={clsx({
          'border-red': !isMobile && forceSuggestHide,
        })}
        ref={inputRef}
        type="text"
        placeholder="Suchbegriff"
        aria-label="Suchbegriff"
        role="textbox"
        onChange={(ev) => inputChangeEvent(ev, false)}
        onKeyPress={(ev) => onInputKeydown(ev, false)}
        onKeyDown={(ev) => onKeydown(ev, false)}
        autoFocus
      />
      <StyledLink
        className={clsx({
          'bg-transparent py-1 px-4 self-center hover:bg-transparent': isMobile,
        })}
        to={`/suchergebnisse#searchquery=${inputRef.current?.value}`}
        onClick={suggestClick}
        primary={!isMobile && true}
      >
        {isMobile ? (
          <Icon name="Search" className="text-5xl -mt-0.5" />
        ) : (
          <FormattedMessage id="search.button" />
        )}
      </StyledLink>
      {!noautosuggest && (
        <StyledAutoSuggests
          ref={suggestRef}
          className={clsx({
            'has-results': resultsAll.length > 1 && !forceSuggestHide,
          })}
          onKeyDown={(ev) => onKeydown(ev, false)}
        >
          {resultsAll.length > 1 && renderAutoSuggest()}
        </StyledAutoSuggests>
      )}
    </div>
  )
}

SearchInput.propTypes = {
  className: PropTypes.string,
  searchfunc: PropTypes.func.isRequired,
  resultsAll: PropTypes.array.isRequired,
  noautosuggest: PropTypes.bool,
  onKeyEnter: PropTypes.func,
  onSuggestClick: PropTypes.func,
  isShown: PropTypes.bool,
  searchquery: PropTypes.string,
}

export default styled(SearchInput).attrs({
  className:
    'relative flex flex-row flex-no-wrap md:border-0 border-2 border-blue rounded-lg',
})`
  min-width: auto;
  ${breakpoint('md')`
    min-width: 80%;
  `}
`
