/**
 * @param {Array<Object>} modules List of staticFields
 * @returns {{getStaticModule: Function, getAllStaticField: Function, getStaticField: Function, getStaticFieldValue: Function, getStaticFieldMedia: Function, getStaticFieldLink: Function, getStaticFieldButton: Function, getAllStaticInfoCards: Function, getStaticInfoCard: Function, getAllStaticFacts: Function, getStaticFact: Function}}
 */
export default function useStaticModule(modules) {
  /**
   * @description Read the fields "value" from staticField.
   * @param {String} key Key of the staticField
   * @returns {String} Value as HTML
   */
  function getStaticModule(key) {
    const module = modules?.find((field) => field.key === key)
    if (!module) console.warn(`Module ${key} error: Not found`)
    return module
  }

  /**
   * @description Get all staticFields from a specific module
   * @param {String} modulekey Key of the module
   */
  function getAllStaticField(modulekey) {
    const module = getStaticModule(modulekey)
    if (!module) return

    const staticFields = module.contentfulfields.filter(
      (field) => field.__typename === 'ContentfulStaticField'
    )
    return staticFields
  }

  /**
   * @description Get specifiy staticField in a specific module
   * @param {String} modulekey Key of the module
   * @param {String} staticfieldkey Key of the staticField
   */
  function getStaticField(modulekey, staticfieldkey) {
    const module = getStaticModule(modulekey)
    if (!module) return

    const staticField = module.contentfulfields.find(
      (field) => field.key === staticfieldkey
    )
    return staticField
  }

  /**
   * @description Read the fields "value" from staticField.
   * @param {String} modulekey Key of the module
   * @param {String} staticfieldkey Key of the staticField
   * @returns {{label: String, link: String}} Button property
   */
  function getStaticFieldValue(modulekey, staticfieldkey) {
    const field = getStaticField(modulekey, staticfieldkey)
    if (!field) return

    return field.value?.childMarkdownRemark?.html
  }

  /**
   * @description Read the fields "media" from staticField.
   * @returns {Object} Media-Object
   * @param modulekey
   * @param staticfieldkey
   */
  function getStaticFieldMedia(modulekey, staticfieldkey) {
    const field = getStaticField(modulekey, staticfieldkey)
    if (!field) return

    return field.media
  }

  /**
   * @description Read the fields "value" and "link" from staticField and generates button property.
   * @param {String} modulekey Key of the module
   * @param {String} staticfieldkey Key of the staticField
   * @returns {{label: String, link: String}} Button property
   */
  function getStaticFieldButton(modulekey, staticfieldkey) {
    const field = getStaticField(modulekey, staticfieldkey)
    if (!field) return

    return { label: field.value?.childMarkdownRemark?.html, link: field.link }
  }

  /**
   * @description Read the field "link" from staticField and generates "link" field property.
   * @param {String} modulekey Key of the module
   * @param {String} staticfieldkey Key of the staticField
   * @returns {{link: String}} Link property
   */
  function getStaticFieldLink(modulekey, staticfieldkey) {
    const field = getStaticField(modulekey, staticfieldkey)
    if (!field) return

    return { link: field.link }
  }

  /**
   * @description Get all InfoCards from a specific module
   * @param {String} modulekey Key of the module
   */
  function getAllStaticInfoCards(modulekey) {
    const module = getStaticModule(modulekey)
    if (!module) return

    const infoCards = module.contentfulfields
      .filter((field) => field.__typename === 'ContentfulInfoCard')
      .map((infoCard) => {
        return {
          headline: infoCard.title,
          copy: infoCard.copy?.childMarkdownRemark?.html,
          button: { label: infoCard.buttonLabel, link: infoCard.buttonLink },
          image: infoCard.image,
          title: infoCard.internalTitle,
        }
      })
    return infoCards
  }

  /**
   * @description Get specifiy InfoCard in a specific module
   * @param {String} modulekey Key of the module
   * @param {String} infocardkey Key of the staticField
   */
  function getStaticInfoCard(modulekey, infocardkey) {
    const module = getStaticModule(modulekey)
    if (!module) return

    const infoCard = module.contentfulfields.find(
      (field) => field.key === infocardkey
    )
    return {
      headline: infoCard.title,
      copy: infoCard.copy?.childMarkdownRemark?.html,
      button: { label: infoCard.buttonLabel, link: infoCard.buttonLink },
      image: infoCard.image?.fluid,
      title: infoCard.internalTitle,
    }
  }

  /**
   * @description Get all Facts from a specific module
   * @param {String} modulekey Key of the module
   */
  function getAllStaticFacts(modulekey) {
    const module = getStaticModule(modulekey)
    if (!module) return

    const facts = module.contentfulfields
      .filter((field) => field.__typename === 'ContentfulFact')
      .map((fact) => {
        return {
          headline: fact.title,
          copy: fact.copy?.childMarkdownRemark?.html,
          image: fact.image,
        }
      })
    return facts
  }

  /**
   * @description Get specifiy Fact in a specific module
   * @param {String} modulekey Key of the module
   * @param {String} factkey Key of the staticField
   */
  function getStaticFact(modulekey, factkey) {
    const module = getStaticModule(modulekey)
    if (!module) return

    const fact = module.contentfulfields.find((field) => field.key === factkey)
    return {
      headline: fact.title,
      copy: fact.copy?.childMarkdownRemark?.html,
      image: fact.image?.fluid,
    }
  }

  return {
    getStaticModule,
    getAllStaticField,
    getStaticField,
    getStaticFieldValue,
    getStaticFieldMedia,
    getStaticFieldButton,
    getStaticFieldLink,
    getAllStaticInfoCards,
    getStaticInfoCard,
    getAllStaticFacts,
    getStaticFact,
  }
}
