const plugin = require('tailwindcss/plugin')

module.exports = plugin(function ({ addComponents, config }) {
  const theme = config('theme')

  const defaultButton = {
    backgroundColor: theme.colors.yellow.default,
    padding: theme.spacing[2] + ' ' + theme.spacing[4],
    height: '42px',
    lineHeight: 1.2,
    transitionProperty: theme.transitionProperty.all,
    transitionDuration: theme.transitionDuration[200],
    borderRadius: theme.borderRadius.lg,
    fontSize: theme.fontSize.lg,
    fontFamily: theme.fontFamily['lt-regular'],
    fontWeight: 400,
    display: 'inline-block',
    textAlign: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.colors.yellow[300],
      textDecoration: 'underline',
      color: theme.colors.black.default,
    },
    '&:focus': {
      outline: '1px dotted #000',
    },
    '&:disabled': {
      backgroundColor: theme.colors['gray-light'].default,
      color: theme.colors.black.default,
    },
  }

  const buttons = {
    '.btn': defaultButton,
    '.btn-large': {
      fontSize: theme.fontSize['2lg'],
      height: '46px',
      fontWeight: 400,
    },
    '.btn-secondary': {
      backgroundColor: theme.colors.blue.default,
      color: theme.colors.white,
      '&:hover': {
        backgroundColor: theme.colors['gray-light'].default,
        color: theme.colors.black.default,
      },
    },
    '.btn-backtop': {
      height: 'auto',
      padding: '15px',
      backgroundColor: theme.colors.red.default,
      color: theme.colors.white,
      borderRadius: theme.borderRadius.full,
      display: 'inline-block',
      position: 'fixed',
      'z-index': 1,
      right: theme.spacing.none,
      bottom: theme.spacing[4],
      '&:hover': {
        backgroundColor: theme.colors.red[300],
      },
    },
    '.btn-icon': {
      height: 'auto',
      padding: '15px',
      display: 'inline-block',
      borderRadius: theme.borderRadius.full,
    },
    '.btn-tag': {
      backgroundColor: theme.colors.white,
      height: theme.spacing[6],
      border: '2px solid' + theme.colors.blue.default,
      fontSize: theme.fontSize.base,
      '&:hover': {
        backgroundColor: theme.colors.red[300],
        border: '2px solid' + theme.colors.red.default,
      },
    },
    '.btn-text': {
      backgroundColor: theme.colors.transparent,
      height: theme.spacing[5],
      fontSize: theme.fontSize.base,
      fontWeight: 500,
      color: theme.colors.red.default,
      padding: 0,
      textAlign: 'left',
      display: 'inline-block',
      borderRadius: 0,
      '&:hover': {
        backgroundColor: theme.colors.transparent,
        color: theme.colors.red.default,
      },
    },
    '.btn-more': {
      backgroundColor: theme.colors.blue.default,
      color: '#fff',
      lineHeight: 1,
      '&:hover': {
        backgroundColor: theme.colors.blue[650],
        color: '#fff',
      },
    },
    '.btn-close': {
      color: '#f00',
      backgroundColor: 'transparent',
      height: 'auto',
      '&:hover': {
        transform: 'scale(1.2)',
        backgroundColor: 'transparent',
        color: '#f00',
        border: 0,
      },
      '&:focus': {
        border: 0,
        outline: 0,
      },
    },
  }

  addComponents(buttons)
})
