const { colors } = require('tailwindcss/defaultTheme')

module.exports = {
  black: {
    ...colors.black,
    default: '#000',
    650: '#575556',
  },
  red: {
    ...colors.red,
    default: '#e3000f',
    300: '#f6b2b7',
  },
  yellow: {
    ...colors.yellow,
    default: '#ffcc00',
    300: '#ffefb2',
  },
  blue: {
    ...colors.blue,
    default: '#00385e',
    650: '#5a7e97',
  },
  'gray-light': {
    ...colors,
    default: '#d0dadd',
    650: '#e0e7e9',
    300: '#f0f3f4',
  },
  gray: {
    ...colors.gray,
    default: '#b1b2b3',
  },
}
