import styled from 'styled-components'
import { Link } from 'gatsby'
import Copy from '@objects/copy'

const BurgerIcon = styled.div.attrs({
  className:
    'flex flex-col justify-between items-stretch items-center w-6 h-4 z-50 relative cursor-pointer',
})`
  > div {
    &:first-child {
      transform: ${(props) =>
        props.active ? 'rotate(45deg) translate(5px, 10px)' : ''};
    }
    &:nth-child(2) {
      transform: ${(props) => (props.active ? 'scale(0)' : '')};
    }
    &:last-child {
      transform: ${(props) =>
        props.active ? 'rotate(-45deg) translate(2px, -8px)' : ''};
    }
  }
`

const BurgerLine = styled.div.attrs({
  className: 'bg-black transition w-full duration-300',
})`
  height: 2px;
`

const MobileNavWrapper = styled.div.attrs({
  className: 'block fixed bottom-0 left-0 top-0 right-0 z-40 overflow-hidden',
})`
  height: ${(props) => (props.active ? 'auto' : 0)};
  width: ${(props) => (props.active ? 'auto' : 0)};
`

const MobileNavOverlay = styled.div.attrs({
  className:
    'block absolute bottom-0 left-0 top-0 right-0 transition-colors duration-300 delay-100',
})`
  background-color: ${(props) =>
    props.active ? 'rgba(0, 0, 0, 0.65)' : 'rgba(0, 0, 0, 0)'};
  height: ${(props) => (props.active ? 'auto' : 0)};
`

const MobileNavOffCanvas = styled.div.attrs({
  className:
    'absolute bg-white h-full min-h-full w-4/5 transition-all duration-300 delay-200 overflow-hidden',
})`
  max-width: 300px;
  right: ${(props) => (props.active ? '0' : '-300px')};
`

const MobileNavContent = styled.div.attrs({
  className:
    'flex flex-col h-full items-stretch text-right px-5 pt-11 pb-3 overflow-y-scroll ',
})`
  flex: 1 300px;
`

const Top = styled.div.attrs({
  className: 'flex flex-col',
})`
  flex: 100%;
`
const Bottom = styled.div.attrs({
  className: 'flex flex-col justify-end',
})`
  flex: 160px;
`

const MobileNavItem = styled(Link).attrs({
  className: 'text-lg block leading-3 font-medium mb-5',
})``

const SvgBg = styled.div.attrs({
  className: 'absolute',
})`
  top: -178px;
  left: -351px;
  width: 500px;
  height: 424px;
  transform: scaleY(-1);
`

const Copyright = styled(Copy).attrs({
  className: 'text-xs font-normal text-center',
})``

export {
  BurgerIcon,
  BurgerLine,
  MobileNavWrapper,
  MobileNavOverlay,
  MobileNavOffCanvas,
  MobileNavContent,
  Top,
  Bottom,
  MobileNavItem,
  SvgBg,
  Copyright,
}
